import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AccountingService } from '../../../services/accounting.service';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PaymMethService } from '../../../services/paymmeth.service';
import { SecurityService } from '../../../services/security.service';
import { AccountAccount,  GroupAccount, GroupByAccount, ParentAccounts } from '../../Interfaces/iaccounting';
import { Icc } from '../../Interfaces/icc';
import { PaymMeth, PaymMethClass } from '../../Interfaces/ipaymmeth';

@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-accountedit',
  templateUrl: './accountedit.component.html',
})
export class AccountEditComponent implements OnInit {
  formAccount: FormGroup;
  Columns: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private accountservice: AccountingService,
    private paymmethservice: PaymMethService,
    private ccservice: CcService,
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private _snackBar: MatSnackBar) {
    this.formAccount = formBuilder.group({
      group: { value: '', disabled: true },
      account: { value: '', disabled: true },
      groupBy: { value: '', disabled: true },
      value: { value: '', disabled: true },
      accountnumber: {value:'', disabled: true },
      description: { value: '', disabled: true },
      numberValue: {value:0,disabled:true}
    });
    this.Columns = ['account', 'description'];
  }
  mensaje: string;
  accoLevel: string = '';
  groupBy: string = '';
  parentAccountList = null;
  groups: GroupAccount[] = [];
  accounts: AccountAccount[] = [];
  groupsby: GroupByAccount[] = [];
  parentAccounts: ParentAccounts[] = [];
  paymMethClasses: PaymMethClass[] = [];
  paymMeth: PaymMeth[] = [];
  cclist: Icc[] = [];

  ngOnInit(): void {
    this.accountservice.getParentAccounts(this.accountservice.accountId).subscribe(result => {
      if (result.body.isValid) {
        for (const entry of result.body.resultData) {
          this.addParentAccount({ id: entry.id, accountNumber: entry.accountNumber, description: entry.description });
        }
        this.parentAccountList = new MatTableDataSource<ParentAccounts>(this.parentAccounts);
      }
    });
    this.accountservice.getAccountFeatures(this.accountservice.accountId).subscribe(result => {
      if (result.body.isValid) {
        this.formAccount.get('accountnumber').setValue(result.body.resultData.accountNumber);
        this.formAccount.get('description').setValue(result.body.resultData.description);
        this.accoLevel = result.body.resultData.level;
        //if (this.accoLevel == "GROUP" || this.accoLevel == "ACCNT") {
        //  this.formAccount.get('group').setValue(result.body.resultData.group);
        //  this.accountservice.getListGroupAccount().subscribe(result => {
        //    if (result.body.isValid) {
        //      for (const entry of result.body.resultData) {
        //        this.addGroup({ id: entry.id, code: entry.code, name: entry.name });
        //      }
        //    }
        //  });
        if (this.accoLevel == "ACCNT" || this.accoLevel == "SUBAC" || this.accoLevel == "AUXLR") {
          //this.openSB(this.accoLevel);
          this.formAccount.get('groupBy').setValue(result.body.resultData.groupBy);
          this.formAccount.get('value').setValue(result.body.resultData.value);
          try {
            this.formAccount.get('numberValue').setValue(Number(result.body.resultData.value));
          }
          catch (e) {
            console.log(e.error);
          }
          this.groupBy = result.body.resultData.groupBy;
          this.accountservice.getAllowedGroups(this.groupBy).subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addGroupBy({ id: entry.id, code: entry.codeallowed, name: entry.name });
              }
            }
            else {
              alert(result.body.error.description);
            }
          });
          //this.accountservice.getListGroupByAccount(this.accountservice.accountId).subscribe(result => {
          //  if (result.body.isValid) {
          //    for (const entry of result.body.resultData) {
          //      this.addGroupBy({ id: entry.id, code: entry.code, name: entry.name });
          //    }
          //  }
          //  else {
          //    alert(result.body.error.description);
          //  }
          //});
          this.paymmethservice.getClass().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addPaymMethClass(entry);
              }
            }
          });
          this.paymmethservice.getPaymMeth().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addPaymMeth({ id: Number(entry.id), code: entry.code, name: entry.name });
              }
            }
          });
          this.ccservice.getCCListV2().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addCC({ cc_id: Number(entry.cc_id), cc_name: entry.cc_name });
              }
            }
          });
        }
      }
      else {
        this.openSB("no hay");

      }
    }, (e) => {
      this.openSB("error " + e.error);

      this.mensaje = e.error;
      console.log(e);
    });
  }


  addParentAccount(parentaccount) {
    this.parentAccounts.push(parentaccount);
  }

  addPaymMethClass(pmc) {
    this.paymMethClasses.push(pmc);
  }

  addCC(cc) {
    //console.log(cc);
    this.cclist.push(cc);
  }

  addPaymMeth(paymmeth) {
    this.paymMeth.push(paymmeth);
  }

  addGroupBy(groupby) {
    //console.log(groupby);
    this.groupsby.push(groupby);
  }

  addGroup(group) {
    this.groups.push(group);
  }

  addAccount(account) {
    this.accounts.push(account);
  }

  b_back() {
    this.router.navigate(['accountlist']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formAccount.get(nombreControl);
    return control.hasError(validacion);
  }

}
