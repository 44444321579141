//import { MatCurrencyFormatModule } from 'mat-currency-format';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Pipe } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CountService } from '../../../../services/count.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { RouterService } from '../../../../services/router.service';
import { iGetTotalCount } from '../../../Interfaces/icount';
import { TargetReport } from '../../../Interfaces/iReport';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-totalCount',
  templateUrl: './totalCount.component.html'
})

@Pipe({ name: 'safeHtml' })
export class TotalCountComponent implements OnInit {
  url: string;
  urlSafe: SafeResourceUrl;
  html: any;
  subRef$: Subscription;
  mensaje: string = "";
  formFilter: FormGroup;
  permissionsList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuGList = new MatTableDataSource;
  progress: number;
  countId: number;

  constructor(
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router,
    private routeparent: RouterService,
    private currencyPipe: CurrencyPipe,
    private _snackBar: MatSnackBar,
    private countservice: CountService,
    private permissionservice: PermissionsService,
    private securityParams: ActivatedRoute

  ) {
    this.formFilter = formBuilder.group({
      pasCode: [''],
      target: [null, Validators.required],
      validator: ['x', Validators.required]  
    })
  }
  target: TargetReport[];


  ngOnInit() {
    this.countId =parseInt( this.securityParams.snapshot.params.countid);
    //target
    this.target = [];
    //ReportTarget
    this.target.push({ name: "Informe", value: "P" });
    //this.target.push({ name: "Archivo CSV", value: "C" });
  }

  Filter() {
    this.mensaje = "Procesando";
    this.formFilter.get('validator').setValue(null);
    const _GTC: iGetTotalCount =
    {
      countId: this.countId, locationId: 0, pasCode: this.formFilter.value.pasCode
    };
    switch (this.formFilter.value.target) {
      case "P":
        this.countservice.GetTotalCount(_GTC).subscribe(result => {
          if (!result.body.isValid) {
            this.mensaje = result.body.error.description;
            this.formFilter.get('validator').setValue('xx');
          }
          else {
            this.html = this.assembleHTMLItem(result.body.resultData.html);
            this.formFilter.get('validator').setValue('xx');
            this.mensaje = "";
          }
        },
          (e) => {
            this.mensaje = e.error;
          });
        break;
    }
  }

  assembleHTMLItem(vp) {
    var strHTML =  vp;
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  b_back() {
    this.router.navigate([this.routeparent.routerParent]);
  }

  b_gen(link, id) {
    this.router.navigate([link]);
  }

  openSB(msg) {
  this._snackBar.open(msg, '', {
            duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
  });
  }

}
