import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CcService } from '../../../../services/cc.service';
import { CountService } from '../../../../services/count.service';
import { DataService } from '../../../../services/data.service';
import { LocationService } from '../../../../services/location.service';
import { PasService } from '../../../../services/pas.service';
import { PermissionsService } from '../../../../services/permissions.service';
//import { PasCodeService } from '../../../../services/pascode.service';
import { RouterService } from '../../../../services/router.service';
import { SecurityService } from '../../../../services/security.service';
import { ErrorStateMatcher1 } from '../../../error-state-matcher1';
import { countstatusResponse } from '../../../Interfaces/icc';
import { locationcount, pascount, pasdata, pasdataResponse } from '../../../Interfaces/ipas';
import { IuserPermissionsResponse } from '../../../Interfaces/iuser';

@Component({
  selector: 'app-tocount',
  templateUrl: './tocount.component.html',
  styleUrls: ['./tocount.component.css']
})

export class TocountComponent implements OnInit, AfterViewInit {
  formReport: FormGroup;
  subRef$: Subscription;
  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  mensaje_info: string = "";
  DataGrid:locationcount[] = [];
  dataList = new MatTableDataSource;
  Columns: string[] = [];
  Cc: countstatusResponse[] = [];
  ccList = null;
  Permissions: IuserPermissionsResponse[] = [];
  pasdataResponse: pasdataResponse;
  pasdata: pasdata;
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  name: string = "";
  code: string = "";
  pas_id: number = 0;
  locationname: string = "";

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private locationService: LocationService,
    private countService:CountService,
    private ccService: CcService,
    private permissionsService: PermissionsService,
    private securityService: SecurityService,
    private routeparent: RouterService,
    private pasService: PasService,
    private _snackBar: MatSnackBar
  ) {
    this.formReport = formBuilder.group({

      code: ['', { validators:[Validators.required],  asyncValidators: [this.codeexist.bind(this)], updateOn: 'blur' } ],
      name: [{ value: null, disabled: true }],
      duedate: [null],/*, Validators.required],*/
      quantity: [null, { validators: [Validators.required]/*, asyncValidators: [this.quantityvalue.bind(this)], updateOn: 'blur' */}],
    });
    this.Columns = ['code', 'name', 'duedate', 'quantity'];
  }
    changes: Subject<void>;
    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;


  selectedvalue: number; selectedview: string;

  ngOnInit() {
    if (this.countService.countingID == 0) this.Abort();
    this.locationname = this.locationService.locationName;
    //permisos
    //let url = environment.urlAPI + 'api/user/permission/';
    this.Permissions = [];
    this.MenuG = [];
    this.permissionsService.getPermission("COUNT-SC").subscribe(results => {
      if (results.body.length == 0) {
        this.mensaje = "No tiene permisos";
        this.mensaje_info = "";
      }
      for (const entry of results.body) {
        //this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource < IuserPermissionsResponse >(this.MenuG);
    },
      (e) => {
        this.mensaje = e.error;
        this.mensaje_info = "";
        console.log(e);
      });
    //cc
  }


  quantityvalue(control: AbstractControl): Observable<ValidationErrors | null> {   //
    if (control.value == 0) {
      this.mensaje = "Debe digitar cantidad";
      this.mensaje_info = "";
      return of({ 'gte': true, 'Debe digitar cantidad': 10 });
      //return false;
    //    map((data: any) => { return true 
    //  })
    }
    else {
      this.mensaje = "";
      this.mensaje_info = "";
      map(res => {
        return  {  oK: true } ;
      })
      // return control.value;
      //map((data: any) => {
      //  return true
      //});
      //return true; // of(true);
    }
  }

  Abort() {
    this.router.navigate(['/ccselect']);
  }

  codeexist(control:FormGroup): Observable<ValidationErrors | null>  {
    this.formReport.controls['quantity'].disable();
    this.formReport.controls['duedate'].disable();

    try {
      return this.pasService.cccodeexist(control.value, this.ccService.ccID)
        .pipe(map(res => {
        if (res.body.isValid) {
          this.name = res.body.resultData.name.trim() + " " + res.body.resultData.presentation.trim();
          this.formReport.controls['quantity'].enable();
          this.mensaje_info = "";
          this.formReport.controls['duedate'].setValue("2000-01-01");
          (res.body.resultData.perishable == false ? this.formReport.controls['duedate'].disable() : this.formReport.controls['duedate'].enable());
          (res.body.resultData.perishable == true ? document.getElementById("duedate").focus() : document.getElementById("quantity").focus());
          //(res.body.resultData.id == 0 ? this.code = "" : null);
          this.pas_id = res.body.resultData.id;
          //don't have consecuenses those above lines
          //this.formReport.controls['code'].clearValidators(); //.setValidators([Validators.min(0)]);
          this.formReport.controls["code"].clearAsyncValidators();
          //
          //this.formReport.controls["code"].updateValueAndValidity();
          //this.formReport.controls["code"].getError(();
          return { errorcode:false};
          //map(res => {
          //  return res ? { exist: true } : null;
          //})

          //return true;
        }
        else {
          this.name = "No existe";
          this.mensaje = res.body.error.description;
          document.getElementById("code").focus();
          return { errorcode: true };
        }
      },
        (e) => {
          console.log(e);
          return { errorcode:true };
        }));
    }
    catch (e) {
      this.formReport.value.name =e.message;
      return of(null);
    }
  }
          

  ok() {
    this.formReport.controls['name'].enable();
    this.formReport.controls['duedate'].enable();
    this.DataGrid.unshift({
      code: this.formReport.value.code, name: this.formReport.value.name,
      duedate: this.formReport.value.duedate, quantity: this.formReport.value.quantity,
      countingnumber:this.locationService.countingnumber, presentation: "",
      user_id: 0, username: "", hardware_id: 0, hardwarename: "", id: 0
    });
    this.dataList = new MatTableDataSource<locationcount>(this.DataGrid);
    this.dataList.paginator = this.paginator;
    this.r_gridregs = this.dataList.data.length;
    const pc: pascount = {
      pas_id:this.pas_id,
      location_id:this.locationService.locationID,
      duedate: (this.formReport.value.duedate == null ? "2000-01-01" : this.formReport.value.duedate),
      hardware_id: 0,
      quantity: this.formReport.value.quantity,
      counting_id: this.countService.countingID,
      countingnumber: this.locationService.countingnumber
    };
    this.countService.SaveLine(pc).subscribe(results => {
    //let url = environment.urlAPI + 'api/count/';
    //this.dataService.post<IgeneralResponse03>(url, pc).subscribe(results => {
      if (results.body.isValid) {
        this.mensaje_info = "Grabado";
        this.mensaje=""
      }
    },
      (e) => {
        this.mensaje_info = "";
        this.mensaje = e.error;
        console.log(e);
      });
    this.formReport.controls['quantity'].reset();
    this.formReport.controls['code'].reset();
    this.formReport.controls['duedate'].reset();
    this.formReport.controls['name'].disable();
    this.formReport.controls['duedate'].disable();
    document.getElementById("code").focus();
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
    }
  }



  b_gen(link, id) {
        this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['locationselect']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formReport.get(nombreControl);
    return control.hasError(validacion);
  }

}
