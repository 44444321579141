import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AccountingService } from '../../../services/accounting.service';
import { CompanyService } from '../../../services/company.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SecurityService } from '../../../services/security.service';
import { UserService } from '../../../services/user.service';
import { accountLoad, accountResponseTree } from '../../Interfaces/iaccounting';
import { graccountlist } from '../../Interfaces/igeneralresponse02';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

interface AccountNode
{
  id: number;
  account: string;
  description: string;
  children?: AccountNode[];
}


/** Flat node with expandable and level information */
interface ExampleFlatNode
{
  expandable: boolean;
  account: string;
  description: string;
  level: number;
  id: number;
}


@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-accountlist',
  templateUrl: './accountlist.component.html',
  styleUrls: ['./accountlist.component.css']
  //providers: [FileDatabase]
})
export class AccountListComponent {
  private _transformer = (node: AccountNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      id:node.id,
      account:node.account,
      description: node.description,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  //isExpandable = (node: ExampleFlatNode) => node.expandable;

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor(
    private companyservice: CompanyService,
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private accountingservice: AccountingService,
    private userservice: UserService,
  private permissionservice:PermissionsService) {
    this.initialize();
  }
  mensaje: string;
  accounts: AccountNode[] = [];
  selectedId: number;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuPop: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;
  menuPopList = new MatTableDataSource;
  message: string = "";

  initialize() {
    this.Permissions = [];
    this.MenuG = [];
    this.menuPop = [];
    this.permissionservice.getPermission("ACCOACCOUNT").subscribe(result => {
      if (result.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      this.menuPopList = new MatTableDataSource<IuserPermissionsResponse>(this.menuPop);

    });
    //const cp: accountLoad = {
    //  companyId: this.companyservice.companyID
    //};
    //let companyId = this.companyservice.companyID;
    this.mensaje = "";
    //let url = environment.urlAPI + 'api/accounting/list/v1';
    this.accountingservice.getAccountList(this.companyservice.companyID).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addAccount({ account: entry.account, description: entry.description });
        }
        this.dataSource.data = results.body.resultData; //   this.accounts; // TREE_DATA; //JSON.parse(td);// 
      }
      else {
        alert(results.body.error.description);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
  }

  addAccount(account) {
    this.accounts.push(account);
  }

  b_back() {
    this.router.navigate(['accounting']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  addPermission(permission) {
    this.Permissions.push(permission);
    switch (permission.type) {
      case "G":
        this.MenuG.push(permission);
        break;
      case "R":
        this.menuPop.push(permission);
        break;
    }
  }

  b_gen(link, code) {
    switch (code) {
      case "I":
        this.accountingservice.Import().subscribe(result => {
          if (result.body.isValid) {
            this.openSB(result.body.resultData.description);
            this.message = "";
            if (result.body.resultData.id > 0) {
              this.initialize();
            }
          }
          else {
            this.message = result.body.error.description;
          }
        }, (e) => {
          this.message = e.error;
          console.log(e);
        });
        break;
    }
  }


  detail(accountnumberid: number) {
    this.accountingservice.putValues(accountnumberid,0);
    this.router.navigate(['accountedit']);

  }

  add(accountnumberid: number) {
    this.accountingservice.putValues(0,accountnumberid);
    this.router.navigate(['accountadd']);
  }

  onClick(node) {
    try {
      this.selectedId = node.id;
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  //menu execute items
  b_execute(prmCode: string, PLink: string) {
    //var _Payment = this.dataGrid.find(x => x.id == this._currentId);
    switch (prmCode) {
      case "C":
        this.accountingservice.putValues(this.selectedId, 0);
        this.detail(this.selectedId);
        break;
      case "A":
        this.accountingservice.putValues(0, this.selectedId);

        this.router.navigate([PLink]);
        break;
    }
  }
}
