import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Pipe } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ReportService } from '../../../services/report.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { SellerService } from '../../../services/seller.service';
import { UserService } from '../../../services/user.service';
import { Icc } from '../../Interfaces/icc';
import { stockstatus } from '../../Interfaces/ipas';
import { TargetReport } from '../../Interfaces/iReport';
import { iGetSellerSales } from '../../Interfaces/iSeller';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-sellerSales',
  templateUrl: './sellerSales.component.html'
})

@Pipe({ name: 'safeHtml' })
export class SellerSalesComponent implements OnInit {
  //url: string;
  urlSafe: SafeResourceUrl;
  html: any;
  subRef$: Subscription;
  mensaje: string = "";
  formFilter: FormGroup;
  permissionsList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuGList = new MatTableDataSource;
  progress: number;

  constructor(
    public sanitizer: DomSanitizer,
    private ccservice: CcService,
    private formBuilder: FormBuilder,
    private securityService: SecurityService,
    private router: Router,
    private userservice: UserService,
    private routeparent: RouterService,
    private currencyPipe: CurrencyPipe,
    private _snackBar: MatSnackBar,
    private sellerservice: SellerService,
    private dataService: DataService,
    private reportService: ReportService,
    private permissionservice: PermissionsService
  ) {
    this.formFilter = formBuilder.group({
      ccId: [0, Validators.required],
      startDate: [new Date().toISOString().substring(0, 10), Validators.required],
      endDate: [new Date().toISOString().substring(0, 10), Validators.required],
      target: [null, Validators.required],
      validator: ['x', Validators.required]  
    })
  }
  ccs: Icc[];
  stockstatus: stockstatus[];
  target: TargetReport[];


  ngOnInit() {
    this.permissionservice.getPermission("SELLERSALES").subscribe(result => {
      if (result.body.length == 0)
        this.mensaje = "No tiene permisos";
      else {
        for (const entry of result.body) {
          this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
        }
        this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
        this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //cc
    this.ccs = [];
    this.ccservice.getCCList().subscribe(result => {
      for (const entry of result.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
    //target
    this.target = [];
    this.target.push({ name: "Informe", value: "P" });
    //this.formFilter.get('startDate').setValue(new Date().toISOString().substring(0, 10));
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      this.MenuG.push(permission);
    }
    else {
    }

  }

  Filter() {
    this.mensaje = "Procesando";
    this.formFilter.get('validator').setValue(null);
    var _StartMonth = new Date(this.formFilter.value.startDate).getMonth() + 1;
    var _EndMonth = new Date(this.formFilter.value.endDate).getMonth() + 1;
    const _par: iGetSellerSales = {
      CCId: this.formFilter.value.ccId,
      startDate:this.formFilter.value.startDate,
      endDate: this.formFilter.value.endDate
    };
    //startDate: new Date(this.formFilter.value.startDate).getFullYear().toString() + _StartMonth.toString().padStart(2, '0') + new Date(this.formFilter.value.startDate).getDate().toString().padStart(2, '0'),
    //  endDate: new Date(this.formFilter.value.endDate).getFullYear().toString() + _EndMonth.toString().padStart(2, '0') + new Date(this.formFilter.value.endDate).getDate().toString().padStart(2, '0')

    switch (this.formFilter.value.target) {
      case "P":
        this.sellerservice.getSellerSales(_par).subscribe(result => {
          if (!result.body.isValid) {
            this.mensaje = result.body.error.description;
            this.formFilter.get('validator').setValue('xx');
          }
          else {
            this.html = this.assembleHTMLItem(result.body.resultData.html);
            this.formFilter.get('validator').setValue('xx');
            this.mensaje = "";
          }
        },
          (e) => {
            this.mensaje = e.error;
          });
        break;

    }
  }

  assembleHTMLItem(vp) {
    var strHTML =  vp;
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  addCc(V_cc) {
    try {
      this.ccs.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  b_back() {
    this.router.navigate([this.routeparent.routerParent]);
  }

  b_gen(link, id) {
    this.router.navigate([link]);
  }

  openSB(msg) {
  this._snackBar.open(msg, '', {
            duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
  });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formFilter.get(nombreControl);
    return control.hasError(validacion);
  }

}
