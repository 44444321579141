/// <reference path="../../../services/data.service.ts" />
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PasService } from '../../../services/pas.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RouterService } from '../../../services/router.service';
import { StockService } from '../../../services/stock.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { Icc } from '../../Interfaces/icc';
import { stock, stockResponse, stockstatus } from '../../Interfaces/ipas';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'app-stocklist',
  templateUrl: './stocklist.component.html',
  styleUrls: ['./stocklist.component.css']
})

export class StockListComponent implements OnInit {

  formReport: FormGroup;
  subRef$: Subscription;

  r_gridregs:number= 0;
  public companyId: string;
  userId: string;
  matcher = new ErrorStateMatcher1();
  mensaje: string="";
  Columns: string[] = [];
  DataGrid: stockResponse[] = [];
  dataList = new MatTableDataSource;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList =new MatTableDataSource;
  filtermenu = { type: 'G' };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataList.paginator = this.paginator;
  }


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    //private securityService: SecurityService,
    private permissionservice: PermissionsService,
    private routeparent: RouterService,
    private passervice: PasService,
    private ccService: CcService,
    private _snackBar: MatSnackBar,
    private stockService: StockService
  ) {
    this.formReport = formBuilder.group({
      cc_id: [0, Validators.required],
      code:[''],
      name: [''],
      stockstatus:[1]
    });
    this.Columns = ['code','name','presentation', 'groupname','stock', 'cccode'];
  }
  selectedvalue: number; selectedview: string;
  cc: Icc[];
  stockstatus:stockstatus[];


  ngOnInit() {
    this.mensaje = "";
    //permisos
    this.Permissions = [];
    this.MenuG = [];
    this.permissionservice.getPermission("STOCK").subscribe(_Result => {
      if (_Result.body.length == 0)
        this.mensaje = "No tiene permisos";
      for (const entry of _Result.body) {
        this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
      }
      this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
      this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //cc
    this.cc = [];
    this.ccService.getCCListV2().subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
        }
      }
      (e) => {
        console.log(e);
      }
    });
    //stockstatus
    this.stockstatus = [];
    this.stockstatus.push({ name: "con existencia", stockstatus: 1 });
    this.stockstatus.push({ name: "sin existencia", stockstatus: 0 });
    this.stockstatus.push({ name: "con existencia negativa", stockstatus: -1 });
  }


  Filtrar() {
    //stock
    const sl: stock = {
      cc_id: this.formReport.value.cc_id,
      code: this.formReport.value.code,
      name: this.formReport.value.name,
      stockstatus: this.formReport.value.stockstatus
    };
    this.mensaje = "";
    this.DataGrid = [];
    this.r_gridregs = 0;
    this.stockService.getStockV1(sl).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.addData({ cc_id: entry.cc_id, code: entry.code, name: entry.name, presentation: entry.presentation, groupname: entry.groupname, stock: entry.stock, cccode: entry.ccCode });
        }
        this.dataList = new MatTableDataSource<stockResponse>(this.DataGrid);
        this.dataList.paginator = this.paginator;
        this.r_gridregs = this.dataList.data.length;
      }
      else {
        this.openSB(results.body.error.description);
        this.mensaje = "No hay datos";
      }
    },
      (e) => {
        this.openSB(e.error);
        this.mensaje = "No hay datos";

        console.log(e);
      });
  }


  addData(data) {
      this.DataGrid.push(data);
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
          this.MenuG.push(permission);
    }
    else {
      switch (permission.code) {
        case "C": {
          this.Columns.push("b_det");
          break;
        }
      }
    }
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }


  b_det(id, name) {
    this.routeparent.putRouter("paslist");
    this.passervice.putValues(id, name)
    this.router.navigate(['/pasdetail']);

  }

  b_back() {
    this.router.navigate(['inventory']);
  }


  b_gen(link, id) {
        this.router.navigate([link]);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

}
