//import { MatCurrencyFormatModule } from 'mat-currency-format';
import { CurrencyPipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Pipe } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountingService } from '../../../services/accounting.service';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ReportService } from '../../../services/report.service';
import { RouterService } from '../../../services/router.service';
import { SecurityService } from '../../../services/security.service';
import { StockService } from '../../../services/stock.service';
import { UserService } from '../../../services/user.service';
import { accountBasicList, accountGetReport } from '../../Interfaces/iaccounting';
import { Icc } from '../../Interfaces/icc';
import { TargetReport } from '../../Interfaces/iReport';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';
//import { GRReport, ReportTarget } from '../../Interfaces/iReport';



@Component({
  selector: 'app-accountingreport',
  templateUrl: './accountingreport.component.html'
})

//template: '<div [innerHtml]="html"></div>'
@Pipe({ name: 'safeHtml' })
export class AccountingReportComponent implements OnInit, OnDestroy {

  url: string;
  urlSafe: SafeResourceUrl;
  html: any;
  subRef$: Subscription;
  mensaje: string = "";
  formFilter: FormGroup;
  permissionsList = null;
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  menuGList = new MatTableDataSource;
  progress: number;

  constructor(
    public sanitizer: DomSanitizer,
    private ccservice: CcService,
    private accountingservice: AccountingService,
    private formBuilder: FormBuilder,
    private securityService: SecurityService,
    private router: Router,
    private userservice: UserService,
    private routeparent: RouterService,
    private currencyPipe: CurrencyPipe,
    private _snackBar: MatSnackBar,
    private stockservice: StockService,
    private dataService: DataService,
    private reportService: ReportService,
    private permissionservice:PermissionsService,
  ) {
    this.formFilter = formBuilder.group({
      ccid: [null, Validators.required],
      accountid: [null, Validators.required],
      target: [null, Validators.required],
      validator: ['x', Validators.required]
    })
  }
  ngOnDestroy(): void {
    //this.subRef$.unsubscribe();
  }

  cc: Icc[];
  account:accountBasicList[];
  target: TargetReport[];


  ngOnInit() {
    this.permissionservice.getPermission("ACCOREPORT").subscribe(result => {
      if (result.body.length == 0)
        this.mensaje = "No tiene permisos";
      else {
        for (const entry of result.body) {
          this.addPermission({ id: entry.id, function_code: entry.function_code, code: entry.code, description: entry.description, type: entry.type, icon: entry.icon, link: entry.link });
        }
        this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
        this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);
      }
    },
      (e) => {
        this.mensaje = e.error;
        console.log(e);
      });
    //cc
    this.cc = [];
    this.ccservice.getCCList().subscribe(result => {
      for (const entry of result.body) {
        this.addCc({ cc_id: entry.cc_id, cc_name: entry.cc_name });
      }
    }, (e) => {
      console.log(e);
    });
    //account basic list
    this.account = [];
    this.addAccount({ account: "", id: 0, description: "Todas" });
    this.accountingservice.getBasicList(0).subscribe(result => {
      for (const entry of result.body) {
        this.addAccount({ account: entry.account, id: entry.id,description: entry.description });
      }
    }, (e) => {
      console.log(e);
    });
    //target
    this.target = [];
    //this.target= this.reportService.GetTarget();
    //ReportTarget
    this.target.push({ name: "Informe", value: "P" });
    //this.target.push({ name: "Archivo CSV", value: "C" });
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      this.MenuG.push(permission);
    }
    else {
    }

  }

  Filter() {
    //this.formFilter.value.validator = [null];
    this.mensaje = "Procesando";
    this.formFilter.get('validator').setValue(null);
    const _par: accountGetReport = {
       ccId: this.formFilter.value.ccid, accountId: this.formFilter.value.accountid , account:this.formFilter.value.account
    };
    switch (this.formFilter.value.target) {
      case "P":
        this.accountingservice.getAccountingRep(_par).subscribe(result => {
          if (!result.body.isValid) {
            this.mensaje = result.body.error.description;
            this.formFilter.get('validator').setValue('xx');
          }
          else {
            this.html = this.assembleHTMLItem(result.body.resultData.html);
            this.formFilter.get('validator').setValue('xx');
            this.mensaje = "";
          }
        },
          (e) => {
            this.mensaje = e.error;
          });
        break;
      case "C":

        break;
    }
  }

  private downloadFile = (data: HttpResponse<Blob>) => {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let _year: string = (new Date().getFullYear().toString());
    let _month: string = ((new Date().getMonth())+1).toString().padStart(2, '0');
    let _day: string = (new Date().getDate().toString().padStart(2, '0'));
    let _sec: string=  (new Date().getHours().toString()+ new Date().getMinutes().toString()+ new Date().getSeconds()).trim();
    a.download = "stockvalued" +_year+_month+_day+_sec+ ".csv"; 
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
    this.openSB('Confirme el archivo ' + a.download );
  }


  assembleHTMLItem(vp) {
    var strHTML =  vp;
    return this.sanitizer.bypassSecurityTrustHtml(strHTML);
  }

  addCc(V_cc) {
    try {
      this.cc.push(V_cc);
    }
    catch (e) {
      console.log(e);
    }
  }

  addAccount(p_Acc) {
    try {
      this.account.push(p_Acc);
    }
    catch (e) {
      console.log(e);
    }
  }

  //Back()
  //{
  //  this.router.navigate([this.routeparent.routerParent]);
  //}

  b_back() {
    this.router.navigate([this.routeparent.routerParent]);
  }

  b_gen(link, id) {
    this.router.navigate([link]);
  }

  openSB(msg) {
  this._snackBar.open(msg, '', {
            duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
  });
  }


}
