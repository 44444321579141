import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
//import { Imenu01Response } from '../../Interfaces/imenu01';
import { environment } from '../../../environments/environment';
import { DataService } from '../../../services/data.service';
import { FileService } from '../../../services/file.service';
import { PasService } from '../../../services/pas.service';
import { StockService } from '../../../services/stock.service';
import { UploadFilesService } from '../../../services/upload-files.service';
import { IResponse } from '../../Interfaces/iresponse';
import { IuserPermissionsResponse } from '../../Interfaces/iuser';

@Component({
  selector: 'arnfilesup-data',
  templateUrl: './arnfilesup.component.html',
  styleUrls: ['./arnfilesup.component.css']
})

export class ArnfilesupComponent {
  Permissions: IuserPermissionsResponse[] = [];
  MenuG: IuserPermissionsResponse[] = [];
  permissionsList = null;
  menuGList = new MatTableDataSource;

  selectedFiles: FileList;
  //Es el array que contiene los items para mostrar el progreso de subida de cada archivo
  progressInfo = []
  message = '';
  fileName = '';
  fileInfos: Observable<any>;

  constructor(private uploadFilesService: UploadFilesService, private dataService: DataService,
    private _snackBar: MatSnackBar,
    private fileService: FileService,
    private pasService: PasService,
    private stockService: StockService,
    private router: Router) { }

  async ngOnInit() {
    this.permissionsList = new MatTableDataSource<IuserPermissionsResponse>(this.Permissions);
    this.menuGList = new MatTableDataSource<IuserPermissionsResponse>(this.MenuG);

    //const url = environment.urlAPI + 'api/company/v1/';
    this.fileInfos = this.uploadFilesService.getFiles();
    //this.fileInfos = this.dataService.get(`${url}/files`);



  }

  selectFiles(event) {
    this.progressInfo = [];
    event.target.files.length == 1 ? this.fileName = event.target.files[0].name : this.fileName = event.target.files.length + " archivos";
    this.selectedFiles = event.target.files;
  }

  uploadFiles() {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  async upload(index, file) {
    switch (file.name.toUpperCase().substring(0, 3)) {
      case "PAS":
        {
          this.progressInfo[index] = { value: 0, fileName: file.name };
          //const url = environment.urlAPI;

          const formData: FormData = new FormData();
          formData.append('files', file);

          //this.dataService.post<IResponse>(url + 'api/file/v1/arionpas', formData).subscribe(results => {
          await this.pasService.UpFilePAS(formData).subscribe(results => {
            if (results.body.isValid) {
              this.openSB(results.body.resultData.description);
              this.progressInfo[index].value = 100;
              this.message = results.body.resultData.description;
            }
            else {
              alert("Error");
              this.message = results.body.error.description;
            }
          },
            (e) => {
              alert(e.error)
              this.message = e.error + '. En ' + file.name;
              console.log(e);
            });
          //this.uploadFilesService.upload(file).subscribe(
          //  event => {
          //    if (event.type === HttpEventType.UploadProgress) {
          //      this.progressInfo[index].value = Math.round(100 * event.loaded / event.total);
          //    } else if (event instanceof HttpResponse) {
          //      this.fileInfos = this.uploadFilesService.getFiles();
          //    }
          //  },
          //  err => {
          //    this.progressInfo[index].value = 0;
          //    this.message = 'No se puede subir el archivo ' + file.name;
          // });
          break;
        }
      case "PCC":
        {
          this.progressInfo[index] = { value: 0, fileName: file.name };
          const url = environment.urlAPI;
          const formData: FormData = new FormData();
          formData.append('files', file);
          this.stockService.UpFileStock(formData).subscribe(results => {
            if (results.body.isValid) {
              this.openSB(results.body.resultData.description);
              this.progressInfo[index].value = 100;
              this.message = results.body.resultData.description;
            }
            else {
              alert("Error");
              this.message = results.body.error.description;
            }
          },
            (e) => {
              alert(e.error)
              this.message = e.error + '. En ' + file.name;
              console.log(e);
            });
          break;
        }
      case "PAC":
        {
          this.progressInfo[index] = { value: 0, fileName: file.name };
          const url = environment.urlAPI;

          const formData: FormData = new FormData();
          formData.append('files', file);
          this.dataService.post<IResponse>(url + 'api/file/v1/arionpascomposition', formData).subscribe(results => {
            if (results.ok) {
              this.openSB(results.body.response);
              this.progressInfo[index].value = 100;
              this.message = results.body.response;
            }
            else {
              alert("Error");
              this.message = results.body.response;
            }
          },
            (e) => {
              alert(e.error)
              this.message = e.error + '. En ' + file.name;
              console.log(e);
            });
          break;
        }
      case "PCA":  //codigos alternos
        {
          this.progressInfo[index] = { value: 0, fileName: file.name };
          const url = environment.urlAPI;

          const formData: FormData = new FormData();
          formData.append('files', file);
          this.dataService.post<IResponse>(url + 'api/file/v1/arionpasalternatecodes', formData).subscribe(results => {
            if (results.ok) {
              this.openSB(results.body.response);
              this.progressInfo[index].value = 100;
              this.message = results.body.response;
            }
            else {
              alert("Error");
              this.message = results.body.response;
            }
          },
            (e) => {
              alert(e.error)
              this.message = e.error + '. En ' + file.name;
              console.log(e);
            });
          break;
        }
      default:
        {
          this.message = "El archivo no cumple con las reglas de nombre";
          break;
        }
    }
  }

  //deleteFile(filename: string) {
  //  this.uploadFilesService.deleteFile(filename).subscribe(res => {
  //    this.message = res['message'];
  //    this.fileInfos = this.uploadFilesService.getFiles();
  //  });
  //}

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 5000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  addPermission(permission) {
    this.Permissions.push(permission);
    if (permission.type == "G") {
      this.MenuG.push(permission);
    }
  }

  b_gen(link, id) {
    this.router.navigate([link]);
  }

  b_back() {
    this.router.navigate(['inventory']);
  }

}
