import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CcService } from '../../../services/cc.service';
import { LocationService } from '../../../services/location.service';
import { PasService } from '../../../services/pas.service';
import { PASPrdService } from '../../../services/PASPrd.service';
import { PrdLocationService } from '../../../services/prdLocation.service';
import { ErrorStateMatcher1 } from '../../error-state-matcher1';
import { IgrouppaslistResponse } from '../../Interfaces/igrouppas';
import { ILocationProductionResponse } from '../../Interfaces/ilocation';
import { GRPASCCV1Detail, IPutDataProduction } from '../../Interfaces/ipas';


@Component({
  selector: 'app-addpasproduction',
  templateUrl: './addpasproduction.component.html'
})

export class AddPASProductionComponent implements OnInit {
  formedit: FormGroup;
  subRef$: Subscription;
  matcher = new ErrorStateMatcher1();
  mensaje: string = "";
  groups: IgrouppaslistResponse[];
  productionLocations: ILocationProductionResponse[];
  dataCCs: GRPASCCV1Detail;
  element: HTMLElement;
  _TaxRegs: number = 0;
  CCs: number[];
  lineCC = {
    dataCC: {
      data: [{ id:0,ccId:0, ccCode: '', ccName: '', locationId: 0,productionTimeOut:0, produced:false}]
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private prdLocationService : PrdLocationService,
    private pasService: PasService,
    private PASPrdService:PASPrdService,
private ccService:CcService,
    private dialogRef: MatDialogRef<AddPASProductionComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.formedit = formBuilder.group({
      dataCC: this.formBuilder.group(
        {
          data: this.formBuilder.array([])
        })
    });
  }
  selectedvalue: number; selectedview: string;
  codeSelect: string;
  PASCode: string=this.pasService.pasName;

  newCCData(entry): FormGroup {
    return this.formBuilder.group({
      id: [entry.id],
      ccId:[entry.ccId],
      ccName: { value: [entry.ccCode + " " + entry.ccName], disabled: true },
      locationId: [entry.locationId,[ Validators.required]],
      productionTimeout: [entry.productionTimeout, [Validators.required, Validators.min(1), Validators.max(2000)]],
      produced: [(entry.locationId == 0 ? false : true), [Validators.required]]
    })
  }

  ngOnInit() {
    //const control = <FormArray>this.formedit.get('dataCC.data');

    //const {id, produced } = control ;
    ////subscribe id
    //this.subRef$ =produced.valueChanges.subscribe(
    //  (idSelect) => {
    //    if (idSelect == 0) {
    //      this.formReport.controls['pasName'].setValidators([Validators.required]);
    //    }
    //    else {
    //      this.formReport.controls['pasName'].setValidators(null);
    //      //this.formedit.controls['taxPercentage'].setValidators([Validators.min(1)]);
    //    }
    //    this.formReport.controls["pasName"].updateValueAndValidity();
    //  }
    //);
    this.CCs = [];
    //production locations
    this.productionLocations = [];
    //const control = <FormArray>this.formedit.get('dataCC.data');
    this.prdLocationService.ProductionList(0).subscribe(results => {
      if (results.body.isValid) {
        for (const entry of results.body.resultData) {
          this.productionLocations.push(entry);
          if (this.CCs.indexOf(entry.ccId)==-1)
          {
            this.CCs.push(entry.ccId);
          }
        }
        const control = <FormArray>this.formedit.get('dataCC.data');
        //cc data
        for (const entry of this.CCs) {
          this.ccService.GetCCData(entry).subscribe(result => {
            this.PASPrdService.DataProduction(result.body.resultData.id, this.pasService.pasID).subscribe(resultpd => {
              if (resultpd.body.isValid) {
                this.lineCC.dataCC.data.forEach(x => {
                  control.push(this.newCCData({ id: resultpd.body.resultData.id, ccId: result.body.resultData.id, ccCode: result.body.resultData.code, ccName: result.body.resultData.name, locationId: resultpd.body.resultData.locationId, productionTimeout: resultpd.body.resultData.productionTimeout, produced: false }));
                });
              }
              else {
                this.lineCC.dataCC.data.forEach(x => {
                  control.push(this.newCCData({ id: 0, ccId: result.body.resultData.id, ccCode: result.body.resultData.code, ccName: result.body.resultData.name, locationId: 0, productionTimeout: 0, produced: false }));
                });
              }
            });
          });
        }
      }
      else {
        this.openSB(results.body.error.description);
        this.dialogRef.close(false);
      }
    }, (e) => {
      console.log(e);
    });
    this.mensaje = "";
  }

  Cancel() {
    this.dialogRef.close();
  }

  Save() {
    try {
      let _Message = "";
      const control = <FormArray>this.formedit.get('dataCC.data');
      control.value.forEach(x => {
        const _PASCC: IPutDataProduction = {
          id:x.id, locationId: x.locationId, productionTimeout: x.productionTimeout, pasId: this.pasService.pasID, transactionCode:(x.produced?"M":"D")
        }
        if (x.produced && x.locationId ==0) {
          alert("Debe definir el centro de producción");
          return;
        }
        if (_PASCC.id > 0 && _PASCC.transactionCode == "M") { //modify
          this.PASPrdService.PutDataProduction(_PASCC).subscribe(result => {
            if (result.body.isValid) {
              _Message = result.body.resultData.description;
              this.openSB(_Message);
            }
            else {
              alert(result.body.error.description);
              return;
            }
          }, (e) => {
            alert(e);
            return;
          });
        }
        else {
          if (_PASCC.id == 0 && _PASCC.transactionCode == "M") { //add
            this.PASPrdService.AddDataProduction(_PASCC).subscribe(result => {
              if (result.body.isValid) {
                _Message = result.body.resultData.description;
                this.openSB(_Message);
              }
              else {
                alert(result.body.error.description);
                return;
              }
            }, (e) => {
              alert(e);
              return;
            });
          }
          else {
            if (_PASCC.id > 0 && _PASCC.transactionCode == "D") { //add
              this.PASPrdService.DeleteDataProduction(_PASCC.id).subscribe(result => {
                if (result.body.isValid) {
                  _Message = result.body.resultData.description;
                  this.openSB(_Message);
                }
                else {
                  alert(result.body.error.description);
                  return;
                }
              }, (e) => {
                alert(e);
                return;
              });
            }
          }
        }
      });
      //if (_Message != "") {
        this.dialogRef.close(true);
      //}
    }
    catch (e) {
      console.log(e.message);
      alert(e.message);
    }
  }

  filterLocations(event,i) {
    return  this.productionLocations.filter(x => { return x.ccId == event.controls.ccId.value });
    //return this.citylistfilter;
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formedit.get(nombreControl);
    return control.hasError(validacion);
  }

  timedisabled(event) {
    if (event.controls.produced.value) {
      event.controls.productionTimeout.enable();
    }
    else {
      event.controls.productionTimeout.disable();

    }
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  ngOnDestroy(): void {
    //this.subRef$.unsubscribe();
  }
}
