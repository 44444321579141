import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AccountingService } from '../../../services/accounting.service';
import { CcService } from '../../../services/cc.service';
import { DataService } from '../../../services/data.service';
import { PaymMethService } from '../../../services/paymmeth.service';
import { SecurityService } from '../../../services/security.service';
import { AccountAccount,  GroupAccount, GroupByAccount, ParentAccounts, putAccount } from '../../Interfaces/iaccounting';
import { PaymMeth, PaymMethClass } from '../../Interfaces/ipaymmeth';
import { Icc } from '../../Interfaces/icc';


@Injectable()
export class FileDatabase {
}

@Component({
  selector: 'app-accountadd',
  templateUrl: './accountadd.component.html',
})
export class AccountAddComponent implements OnInit {
  formAccount: FormGroup;
  Columns: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private accountservice: AccountingService,
    private paymmethservice: PaymMethService,
    private ccservice: CcService,
    private dataService: DataService,
    private securityService: SecurityService,
    private router: Router,
    private _snackBar: MatSnackBar) {
    this.formAccount = formBuilder.group({
      //group: ['', Validators.required],
      //account: { value: '', disabled: true },
      accountMaster: { value: '', disabled: true },
      accountNumber: ['', Validators.required],
      description: ['', Validators.required],
      groupBy: ['', Validators.required],
      value: ['', Validators.required],
    });
    this.Columns = ['account', 'description'];
  }
  mensaje: string;
  accoLevel: string = '';
  groupBy: string = '';
  parentAccountList = null;
  groups: GroupAccount[] = [];
  accounts: AccountAccount[] = [];
  groupsby: GroupByAccount[] = [];
  parentAccounts: ParentAccounts[] = [];
  paymMethClasses: PaymMethClass[] = [];
  paymMeth: PaymMeth[] = [];
  cclist : Icc[] = [];

  ngOnInit(): void {
    if (this.accountservice.parentAccountId > 0) {
      this.accountservice.getParentAccountsInclusive(this.accountservice.parentAccountId).subscribe(result => {
        if (result.body.isValid) {
          for (const entry of result.body.resultData) {
            this.addParentAccount({ id: entry.id, accountNumber: entry.accountNumber, description: entry.description });
            this.formAccount.get('accountMaster').setValue(entry.accountNumber.trim())
          }
          this.parentAccountList = new MatTableDataSource<ParentAccounts>(this.parentAccounts);
        }
      });
    }
    this.accountservice.getChildAccountFeatures(this.accountservice.parentAccountId).subscribe(result => {
      if (result.body.isValid) {
        this.formAccount.get('accountNumber').setValue(result.body.resultData.suggestedAccount);
        this.formAccount.controls["accountNumber"].setValidators([Validators.required, Validators.minLength(result.body.resultData.length), Validators.maxLength(result.body.resultData.length)]);
        this.accoLevel = result.body.resultData.level;
        if (this.accoLevel == "ACCNT" || this.accoLevel=="SUBAC" || this.accoLevel=="AUXLR") {
          this.formAccount.get('groupBy').setValue(result.body.resultData.groupBy);
          this.formAccount.get('value').setValue(result.body.resultData.value);
          this.groupBy = (result.body.resultData.groupBy.trim() == '' ? 'GLOBL' : result.body.resultData.groupBy);
          this.accountservice.getAllowedGroups(this.groupBy).subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addGroupBy({ id: entry.id, code: entry.codeallowed, name: entry.name });
              }
            }
            else {
              alert(result.body.error.description);
            }
          });
          //this.accountservice.getListGroupByAccount(this.accountservice.parentAccountId).subscribe(result => {
          //  if (result.body.isValid) {
          //    for (const entry of result.body.resultData) {
          //      this.addGroupBy({ id: entry.id, code: entry.code, name: entry.name });
          //    }
          //  }
          //});
          this.paymmethservice.getClass().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addPaymMethClass(entry);
              }
            }
          });
          this.paymmethservice.getPaymMeth().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addPaymMeth(entry);
              }
            }
          });
          this.ccservice.getCCListV2().subscribe(result => {
            if (result.body.isValid) {
              for (const entry of result.body.resultData) {
                this.addCC(entry);
              }
            }
          });

        }
      }
      else {
        this.openSB("no hay");
      }
    }, (e) => {
      this.openSB("error "+e.error);
      this.mensaje = e.error;
    });
  }


  addParentAccount(parentaccount) {
    this.parentAccounts.push(parentaccount);
  }

  addPaymMethClass(pmc) {
    this.paymMethClasses.push(pmc);
  }

  addPaymMeth(paymmeth) {
    this.paymMeth.push(paymmeth);
  }

  addCC(cc) {
    //console.log(cc);
    this.cclist.push(cc);
  }

  addGroupBy(groupby) {
    this.groupsby.push(groupby);
  }

  addGroup(group) {
    this.groups.push(group);
  }

  addAccount(account) {
    this.accounts.push(account);
  }


  cancel() {
    this.router.navigate(['accountlist']);
  }

  openSB(msg) {
    this._snackBar.open(msg, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: ['snackbarmsg']
    });
  }

  hasError(nombreControl: string, validacion: string) {
    const control = this.formAccount.get(nombreControl);
    return control.hasError(validacion);
  }

  save() {
    try {
      //this.formAccount.value.groupBy.to
      //console.log(this.formAccount.value.accountMaster);
      const Acc: putAccount = {
        accountNumber: this.formAccount.get("accountMaster").value + this.formAccount.value.accountNumber,
        description: this.formAccount.value.description, group: '', account: '', groupBy: this.formAccount.value.groupBy,
        value: this.formAccount.value.value.toString()
      };
      this.accountservice.putAccount(Acc).subscribe(result => {
        if (result.body.isValid) {
          this.openSB(result.body.resultData.description);
          this.router.navigate(['accountlist']);
        }
        else { alert(result.body.error.description); }
      },
        (e) => {
          alert(e.error);
        }
      );
    }
    catch (e) {
      //console.log(e.message);
      alert(e.message);
    }
  }
}
